@import '../../../shared';

@include mq($from: 1480px) {
  .hub-module-dark-theme,
  .hub-module-brand-dark-theme,
  .hub-module-brand-light-theme {
    &:not(:last-child) {
      @include right-jigsaw(
        var(--jigsaw-width),
        var(--jigsaw-height),
        var(--jigsaw-radius),
        #fff
      );

      .hub-module-wrapper {
        padding-bottom: calc(var(--hub-module-padding) + var(--jigsaw-height));
      }
    }
  }

  .hub-module-light-theme {
    &:not(:last-child) {
      @include left-jigsaw(
        var(--jigsaw-width),
        var(--jigsaw-height),
        var(--jigsaw-radius),
        var(--hub-module-padding),
        #fff
      );

      .hub-module-wrapper {
        padding-bottom: 0;
      }
    }
  }

  .hub-module-dark-theme,
  .hub-module-light-theme,
  .hub-module-brand-dark-theme,
  .hub-module-brand-light-theme {
    + .hub-module-light-theme {
      .hub-module-wrapper {
        padding-top: 0;
        margin-bottom: calc(
          (var(--hub-module-padding) - var(--jigsaw-height)) * 2
        );
      }

      .hub-module-body {
        top: calc(var(--hub-module-padding) - var(--jigsaw-height));
      }
    }
  }

  .hub-module-light-theme {
    + .hub-module-dark-theme,
    + .hub-module-brand-dark-theme,
    + .hub-module-brand-light-theme {
      .hub-module-wrapper {
        padding-top: calc(var(--hub-module-padding) + var(--jigsaw-height));
      }
    }
  }

  .hub-module-light-theme {
    + .hub-module-light-theme {
      .hub-module-wrapper {
        padding-top: calc(var(--hub-module-padding) + var(--jigsaw-height));
      }
    }
  }

  .hub-module-dark-theme,
  .hub-module-brand-dark-theme,
  .hub-module-brand-light-theme {
    + .hub-module-dark-theme,
    + .hub-module-brand-dark-theme,
    + .hub-module-brand-light-theme {
      margin-top: calc(var(--jigsaw-height) * -1);
      z-index: 1;
    }
  }

  .hub-module-light-theme:last-child {
    .hub-module-wrapper {
      margin-bottom: 0;
    }
  }
}
