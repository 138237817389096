@import '../main/theme';
@import '../main/jigsaw';

:root {
  /**
   * Classic Header -> src/components/headers/ClassicHeader/index.tsx
   */
  --classic-header-logo-height: 31.5px;
  --classic-header-heading-font-weight: 300;

  @include mq($from: desktop) {
    --classic-header-height: 85px;
    --classic-header-logo-height: 63px;
  }

  /**
   * Hub Home Hero Banner (Brand Hub only)
   */
  .brand-hub .hub-home-hero-module {
    --hub-hero-module-value-proposition-font-size: 24px;

    @include mq($from: desktop) {
      --hub-hero-module-value-proposition-font-size: 32px;
    }

    @include mq($from: wide) {
      --hub-hero-module-value-proposition-font-size: 65px;
    }

    --hub-hero-module-value-proposition-font-weight: 300;
    --hub-hero-module-value-proposition-line-height: 140%;
  }

  /**
  * Hub Column Text Module
  */
  --hub-column-text-module-icon-width: 57px;
  --hub-column-text-module-icon-height: 63px;

  /**
   * Classic Footer -> src/components/footers/ClassicFooter/index.tsx
   */
  --classic-footer-logo-max-height: 65px;
}

.brand-hub {
  $jigsaw-fix: 150px;
  @import '../main/orb';

  .hub-home-hero-module {
    @include mq($from: tablet) {
      min-height: 290px;
    }

    @include mq($from: desktop) {
      min-height: 490px;
    }

    @include mq($from: wide) {
      min-height: 690px;
    }
  }
}
