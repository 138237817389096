$jigsaw-fix: 0 !default;

@keyframes orb {
  from {
    transform: translate(var(--hero-orb-x), var(--hero-orb-y)) scale(0.6);
  }
  to {
    transform: translate(0, 0) scale(1);
  }
}

@mixin orb() {
  animation-name: orb;
  animation-duration: 8s;
  animation-timing-function: ease-in-out;
}

.hub-home-hero-module {
  .hub-module-styled-element-1 {
    position: absolute;
    right: -250px;
    bottom: $jigsaw-fix - 20px;
    width: 790px;
    height: 812px;
    background: url('../../../../assets/images/ica/light-orb.png') no-repeat;
    background-size: contain;

    --hero-orb-x: 0;
    --hero-orb-y: 100%;
    @include orb();
  }

  .hub-module-styled-element-2 {
    position: absolute;
    right: 150px;
    bottom: $jigsaw-fix - 15px;
    width: 462px;
    height: 480px;
    background: url('../../../../assets/images/ica/primary-orb.png') no-repeat;
    background-size: contain;

    --hero-orb-x: 0;
    --hero-orb-y: 100%;
    @include orb();
  }

  .hub-module-styled-element-3 {
    position: absolute;
    right: 50px;
    bottom: $jigsaw-fix - 120px;
    width: 324px;
    height: 324px;
    background: url('../../../../assets/images/ica/medium-orb.png') no-repeat;
    background-size: contain;

    --hero-orb-x: 0;
    --hero-orb-y: 100%;
    @include orb();
  }
}
